<template>
  <div class="leftRightLayout page">
    <!--<div class="leftLayout">
            <div class="el-scrollbar">
                <div class="el-scrollbar__wrap">
                    <div class="el-scrollbar__view">
                        <el-menu @select="changeLog" style="margin-top:10px">
                            <el-menu-item index="1">
                                <i class="el-icon-setting"></i>
                                <span slot="title">登陆日志</span>
                            </el-menu-item>
                            <el-menu-item index="2">
                                <i class="el-icon-setting"></i>
                                <span slot="title">访问日志</span>
                            </el-menu-item>
                            <el-menu-item index="3">
                                <i class="el-icon-setting"></i>
                                <span slot="title">异常日志</span>
                            </el-menu-item>
                        </el-menu>
                    </div>
                </div>
            </div>
        </div>-->
    <div class="rightLayout">
      <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
        @keyup.enter.native="refreshList()" @submit.native.prevent>
        <el-form-item prop="searchDates">
          <el-date-picker v-model="searchDates" type="daterange" size="small" align="right"
            value-format="yyyy-MM-dd hh:mm:ss" unlink-panels range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="title">
          <el-input size="small" v-model="searchForm.title" placeholder="请输入关键词" clearable></el-input>
        </el-form-item>
        <el-form-item prop="type">
          <el-select v-model="searchForm.type" clearable placeholder="操作类型">
            <el-option v-for="item in $dictUtils.getDictList('log_type')" :key="item.label" :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="refreshList()" size="small" icon="el-icon-search">查询</el-button>
          <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="bg-white top">
        <el-row>
          <el-button-group class="f_r">
            <el-tooltip class="item" effect="dark" content="刷新" placement="top">
              <el-button type="default" size="small" icon="el-icon-refresh" @click="refreshList">
              </el-button>
            </el-tooltip>
          </el-button-group>
        </el-row>
        <el-table :data="dataList" v-loading="loading" size="small" height="calc(100% - 80px)"
          @selection-change="selectionChangeHandle" class="table">
          <el-table-column prop="formatDate" show-overflow-tooltip label="操作时间">
          </el-table-column>
          <el-table-column prop="operId" show-overflow-tooltip label="操作人">
          </el-table-column>
          <el-table-column prop="operIp" show-overflow-tooltip label="操作人ip">
          </el-table-column>
          <el-table-column prop="operModular" show-overflow-tooltip label="操作模块">
          </el-table-column>
          <el-table-column prop="operType" show-overflow-tooltip label="操作类型">
          </el-table-column>
          <el-table-column prop="parameter" show-overflow-tooltip label="请求参数">
          </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  data() {
    return {
      searchForm: {
        mode: "",
        type: "",
        title: '',
        createBy: {
          name: ''
        },
        requestUri: '',
        beginDate: '',
        endDate: ''
      },
      searchDates: [],
      dataList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      dataListSelections: [],
      params: 1,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  activated() {
    this.refreshList()
  },
  watch: {
    searchDates() {
      if (this.searchDates) {
        this.searchForm.beginDate = this.searchDates[0]
        this.searchForm.endDate = this.searchDates[1]
      } else {
        this.searchForm.beginDate = ''
        this.searchForm.endDate = ''
      }
    }
  },
  methods: {
    // 获取数据列表
    refreshList() {
      let params = {};
      let paramsJson = {};

      paramsJson.search = this.searchForm.title;//关键字
      paramsJson.type = this.searchForm.type;//操作类型
      paramsJson.start = this.searchForm.beginDate;//开始时间
      paramsJson.end = this.searchForm.endDate;//结束时间
      params.paramsJson = JSON.stringify(paramsJson);

      this.loading = true
      this.$axios(this.api.allCommon.findList, {
        'paramsJson': params.paramsJson,
        'page': this.pageNo,
        'size': this.pageSize
      }, 'get').then(data => {
        this.dataList = data.data.records
        var number = parseInt(data.data.total);
        this.total = number
        this.loading = false

      })
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()

    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    resetSearch() {
      this.searchDates = []
      this.$refs.searchForm.resetFields()
      this.searchForm.mode = '';
      this.$nextTick(() => {
        this.refreshList()
      })
    }
  }
}
</script>
